<template>
  <div
    :style="
      $vuetify.breakpoint.smAndDown ? 'padding: 40px 0px' : 'padding: 70px 0px'
    "
  >
    <v-main>
      <v-container>
        <v-row no-gutters align="start">
          <div
            style="
              width: 12px;
              height: 12px;
              background: #144fa9;
              border-radius: 50%;
              margin-top: 12px;
            "
          />
          <v-col style="margin-left: 8px">
            <h2
              style="
                color: var(--Black, #1b1b1b);
                font-family: 'MacPaw Fixel';
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              "
            >
              {{ "FAQ_section" | localize }}
            </h2>
            <p
              style="
                color: var(--Black, #1b1b1b);
                font-family: 'MacPaw Fixel';
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0px;
              "
            >
              {{ "FAQ_section_subtitle" | localize }}
            </p>
          </v-col>
        </v-row>
        <v-expansion-panels style="margin-top: 40px" v-model="panel">
          <v-expansion-panel
            v-for="(item, index) in FAQList"
            :key="item.id"
            style="margin-bottom: 20px; background: #f6f8fc"
          >
            <v-expansion-panel-header class="FAQTitle" expand-icon="">
              <v-row
                justify="start"
                align="center"
                :style="
                  $vuetify.breakpoint.xs
                    ? 'position: relative;'
                    : 'position: relative; padding-right: 45px;'
                "
              >
                <div style="width: 24px">
                  <img
                    src="@/assets/img/iconsSvg/plusIcon.svg"
                    v-if="index !== activeItem"
                    alt="plus"
                  />
                  <img
                    src="@/assets/img/iconsSvg/minusIcon.svg"
                    v-if="index == activeItem"
                    alt="minus"
                  />
                </div>
                <v-col style="text-align: justify">
                  <span>{{ item.translations.question }}</span>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="FAQText">
              {{ item.translations.answer }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import FAQService from "../../../../requests/Admin/FAQService";
export default {
  data: () => ({
    active: true,
    activeItem: -1,
    isShowContent: false,
    panel: -1,
    FAQList: [],
  }),
  mounted() {
    this.getFAQs();
  },
  methods: {
    async getFAQs() {
      await FAQService.getFAQForMain().then((res) => {
        if (res.status == "Success") {
          this.FAQList = res.data;
        }
      });
    },
  },
  watch: {
    panel: {
      deep: true,
      handler() {
        this.activeItem = this.panel;
        this.active = true;
      },
    },
  },
};
</script>

<style scoped>
.FAQTitle {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.FAQText {
  color: #767e86;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>