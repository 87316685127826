import requestService from "../requestService";
import store from '@/store';

export default {
    async getFAQs(page) {
        const response = await requestService.get(`/cp_dash/faq?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getFAQForMain(page) {
        const response = await requestService.get(`/v1/faq?page=${page}`, {
            headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
        })
        return response?.data
    },
    async createFAQ(form) {
        const response = await requestService.post(`/cp_dash/faq`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async updateFAQ(id, form) {
        const response = await requestService.post(`/cp_dash/faq/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async deleteFAQ(id) {
        const response = await requestService.delete(`/cp_dash/faq/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },

}